import React, { useState } from "react";
import { useSnackbar } from "contexts/SnackbarContext";
import { handleContact } from "utils";

const Contact = () => {
  const { OpenSnackbar } = useSnackbar();
  const [Loading, setLoading] = useState(false);

  const [contact, setContact] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleInput = (e) => {
    setContact({ ...contact, [e.target.name]: e.target.value });
  };

  const handleForm = (e) => {
    e.preventDefault();
    handleContact(contact, setContact, setLoading, OpenSnackbar);
  };

  return (
    <section className="contact" id="Contact">
      <div className="container">
        <div className="col-12 d-flex justify-content-center text-center">
          <div className="title">
            <h1>Contact</h1>
          </div>
        </div>
        <div className="row d-flex justify-content-center my-lg-4 my-md-4 my-3">
          <div className="col-lg-6 col-md-8 col-12 d-flex justify-content-center">
            <form method="post" onSubmit={handleForm}>
              <div className="row form_control">
                <div className="col-12 d-flex justify-content-center">
                  <input
                    type="text"
                    name="name"
                    required
                    autoComplete="off"
                    placeholder="Full Name"
                    id="ControllerName"
                    value={contact.name}
                    onChange={handleInput}
                    disabled={Loading ? true : false}
                    className={Loading ? "not-allowed" : null}
                  />
                </div>
              </div>
              <div className="row form_control">
                <div className="col-12 d-flex justify-content-center">
                  <input
                    type="text"
                    name="email"
                    value={contact.email}
                    required
                    autoComplete="off"
                    id="ControllerEmail"
                    placeholder="Email Address"
                    disabled={Loading ? true : false}
                    className={Loading ? "not-allowed" : null}
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div className="row form_control">
                <div className="col-12 d-flex justify-content-center">
                  <textarea
                    name="message"
                    rows="4"
                    cols="20"
                    id="ControllerMessage"
                    disabled={Loading ? true : false}
                    className={Loading ? "not-allowed" : null}
                    value={contact.message}
                    required
                    autoComplete="off"
                    placeholder="Message"
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div className="row form_control mt-3">
                <div className="col-12 d-flex justify-content-center">
                  <button
                    type="submit"
                    disabled={Loading ? true : false}
                    className={Loading ? "not-allowed" : null}
                  >
                    {Loading ? (
                      <p
                        style={{
                          color: "snow",
                          fontSize: "1.7rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItem: "center",
                          marginTop: "10px",
                        }}
                      >
                        <i class="zmdi zmdi-rotate-left zmdi-hc-spin-reverse"></i>
                      </p>
                    ) : (
                      "Contact"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
