import React from "react";
import Table from "./Table";
import TokenomicsChart from "./TokenomicsChart";

const Tokenomics = () => {
  return (
    <>
      <div className="container Tokenomics">
        <div className="row d-flex align-items-center mt-4">
          <div className="col-lg-8 col-md-8 col-12 order-lg-1 order-md-1 order-2">
            <Table />
          </div>
          <div className="col-lg-4 col-md-4 col-12 order-lg-2 order-md-2 order-1">
            <TokenomicsChart />
          </div>
        </div>
      </div>
    </>
  );
};

export default Tokenomics;
