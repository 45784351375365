import axios from "axios";
import api from "api";

export const handleContact = async (
  contact,
  setContact,
  setLoading,
  OpenSnackbar
) => {
  try {
    setLoading(true);

    const { name, email, message } = contact;

    const response = await axios.post(api.createContact, {
      name,
      email,
      message,
    });

    if (response.status === 201) {
      OpenSnackbar(true, "Success", response.data.message);
      setContact({
        name: "",
        email: "",
        message: "",
      });
      setLoading(false);
    } else if (response.status === 203) {
      OpenSnackbar(true, "Error", response.data.error);
      setLoading(false);
    }
  } catch (error) {
    OpenSnackbar(true, "Error", "Email not sent");
    setLoading(false);
  }
};
