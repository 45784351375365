import React, { useEffect } from "react";
import { MediaRegistry } from "registry";

const Footer = () => {
  useEffect(() => {
    var newDate = new Date();
    let year = newDate.getFullYear();
    document.getElementById("year").innerHTML = `${year} by LP Finance Inc.`;
  }, []);

  return (
    <footer>
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-lg-3 col-12 d-flex justify-content-lg-start justify-content-center">
            <div className="small_header">
              <div className="icons_card">
                {MediaRegistry.map((items, ind) => {
                  return (
                    <a
                      href={items.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={ind}
                    >
                      <img src={items.icon} alt={items.name} />
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12 c d-flex align-items-center justify-content-center flex-column text-center my-lg-0 my-2">
            <div className="title">
              <p>Engineering the standard of synthetics</p>
            </div>
            <div className="subtitle d-flex align-items-center justify-content-center">
              <span>Powered by</span>
              <img src="/images/solana.png" alt="solana" loading="lazy" />
            </div>
          </div>
          <div className="col-lg-3 col-12 d-flex justify-content-lg-end justify-content-center">
            <div className="copyright">
              <p id="year"></p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
