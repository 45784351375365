import React from "react";

const Banner = () => {
  return (
    <section className="Banner">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-8 col-sm-8 col-12">
            <div className="Banner_card">
              <div className="title">
                <h1>LP Finance Labs.</h1>
              </div>
              <div className="subtitle">
                <p>Leverage Staking Yields 20x.</p>
                <p>Short Sell SOL.</p>
                <p>Permissionless TWAP.</p>
                <p>Stake LPFi. Earn Revenue.</p>
              </div>

              <div className="btn_section">
                <div className="row">
                  <div className="col-lg-5 col-6">
                    <a
                      href="https://app.lp.finance/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="app_btn">Leverage</button>
                    </a>
                  </div>
                  <div className="col-lg-5 col-6">
                    <a
                      href="https://twap.so"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>
                        <span>TWAP</span>
                      </button>
                    </a>
                  </div>
                  <div className="col-lg-10 col-12 mt-3">
                    <a
                      href=" https://staking.lp.finance"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="d-flex align-items-center">
                        <img src="/favicon.ico" alt="favicon" loading="lazy" />
                        <span className="ml-1">LPFi Staking</span>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
