import React from "react";
import { LPIncentivesItems } from "registry";

const HeadersList = ["Allocation", "Price", "Vesting"];

const Table = () => {
  return (
    <div className="row mt-4 Tokenomics_table_section pb-3">
      <div className="col-12">
        <div className="table_card">
          <table className="table table-hover">
            <thead>
              <tr>
                {HeadersList.map((head, ind) => {
                  return (
                    <th scope="col" key={ind}>
                      <p>{head}</p>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {LPIncentivesItems.map((list, ind) => {
                return (
                  <tr key={ind}>
                    <td>
                      <p>{list.Allocation}</p>
                    </td>
                    <td>
                      <p>${list.Price}</p>
                    </td>
                    <td>
                      <p>{list.Vesting}</p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Table;
