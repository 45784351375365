export const ImgRegistry = {
  zSOL: "https://lptokenbucket.s3.amazonaws.com/30bd9d88075d07b6a14086c63e9ac124",
  LPFi: "https://lptokenbucket.s3.amazonaws.com/ce97477a6c673ea64233e88e06724341",
  ETH: "https://lptokenbucket.s3.amazonaws.com/eth.png"
};

export const NavbarRegistry = [
  {
    id: 1,
    name: "Home",
    pathName: "/",
    href: "/",
  },
  {
    id: 2,
    name: "Overview",
    pathName: "/Overview",
    href: "#Overview",
  },
  {
    id: 3,
    name: "Documentation",
    pathName: "https://docs.lp.finance",
    href: "https://docs.lp.finance"
  },
  // {
  //   id: 3,
  //   name: "Core Team",
  //   pathName: "/Core Team",
  //   href: "#Core Team",
  // },
  {
    id: 4,
    name: "Contact",
    pathName: "/Contact",
    href: "#Contact",
  },
];

export const MediaRegistry = [
  {
    id: 1,
    name: "twitter",
    icon: "/images/media/twitter.png",
    url: "https://twitter.com/LPFinance_",
  },
  {
    id: 2,
    name: "telegram",
    icon: "/images/media/telegram.png",
    url: "https://t.me/LP_Defi_Official_group",
  },
  {
    id: 3,
    name: "medium",
    icon: "/images/media/medium.png",
    url: "https://lp-finance.medium.com",
  },
  {
    id: 4,
    name: "linkedin",
    icon: "/images/media/linkedin.png",
    url: "https://www.linkedin.com/company/lpdefi/",
  },
  {
    id: 5,
    name: "github",
    icon: "/images/media/github.png",
    url: "https://github.com/LP-Finance-Inc",
  },
  {
    id: 6,
    name: "discord",
    icon: "/images/media/discord.png",
    url: "https://discord.gg/ug7mstrHNW",
  },
];

export const OverviewRegistry = [
  {
    id: 1,
    title: "20x APY on mSOL",
    subtitle: "Leverage liquid staking yields up to 20x leverage.",
    imgUrl: "/images/zSOL.png",
  },
  {
    id: 2,
    title: "Short-sell SOL",
    subtitle:  "Short-sell SOL with the lowest fee possible.",
    imgUrl: "/images/bear.png",
  },
  {
    id: 3,
    title: "Stake LPFi",
    subtitle: "Earn protocol fees.",
    imgUrl: "/logo192.png",
  },
  {
    id: 4,
    title: "TWAP",
    subtitle: "Swap Wormhole bridged assets with TWAP.",
    imgUrl: "/images/wormhole.png",
  },
];

export const teamRegistry = [
  {
    id: 1,
    name: "Joohwan Lee",
    position: "Protocol Architect",
    imgUrl: "/images/Team/eric.png",
    media: [
      {
        id: 2,
        name: "twitter.png",
        url: "https://twitter.com/ponzishorter",
      },
      {
        id: 3,
        name: "linkedin.png",
        url: "https://www.linkedin.com/in/eric-lee-a6b502208/",
      },
    ],
  },
  {
    id: 4,
    name: "Cui Yonghu",
    position: "Rust Engineer",
    imgUrl: "/images/Team/cui.png",
    media: [
      {
        id: 5,
        name: "linkedin.png",
        url: "https://www.linkedin.com/in/cui-yonghu-a03b64199/",
      },
    ],
  },
  {
    id: 6,
    name: "Deepak kurmi",
    position: "Software Engineer",
    imgUrl: "/images/Team/dk.png",
    media: [
      {
        id: 7,
        name: "linkedin.png",
        url: "https://www.linkedin.com/in/deepak-kurmi-16b236206/",
      },
    ],
  },
];

export const LPIncentivesItems = [
  {
    id: 1,
    Allocation: "Liquidity Mining",
    Price: 0,
    Vesting: "0% at TGE. Linear Vesting for 5 months.",
  },
  {
    id: 2,
    Allocation: "Team",
    Price: 0,
    Vesting: "0% at TGE. 12 months cliff. Linear Vesting for 40 months.",
  },
  {
    id: 3,
    Allocation: "Strategic",
    Price: 0,
    Vesting: "0% at TGE. 6 months cliff. Linear Vesting for 10 months.",
  },
  {
    id: 4,
    Allocation: "IDO",
    Price: 0.3,
    Vesting: "50% at TGE. Linear Vesting for 5 months.",
  },
  {
    id: 5,
    Allocation: "Pre-seed",
    Price: 0.2,
    Vesting: "0% at TGE. 1 month cliff. Linear Vesting for 20 months.",
  },
  {
    id: 6,
    Allocation: "Pre-seed(Cogitent)",
    Price: 0.2,
    Vesting: "10% at TGE. Linear Vesting for 18 months.",
  },
  {
    id: 7,
    Allocation: "Liquidity",
    Price: 0,
    Vesting: "40% at TGE. Linear Vesting for 5 months.",
  },
  {
    id: 8,
    Allocation: "Treasury",
    Price: 0,
    Vesting: "100% at TGE",
  },
  {
    id: 9,
    Allocation: "xLPFi",
    Price: 0,
    Vesting: "100% at TGE",
  },
];

export const TokenomicsList = [
  {
    name: "Pre-seed",
    value: 20,
    fill: "white",
  },
  {
    name: "Strategic",
    value: 20,
    fill: "#f09c1d",
  },
  {
    name: "IDO",
    value: 2.5,
    fill: "#57ed98",
  },
  {
    name: "Team",
    value: 15,
    fill: "#51c8d1",
  },
  {
    name: "LM",
    value: 15,
    fill: "#cdd400",
  },
  {
    name: "Pre-seed(Cogitent)",
    value: 9.5,
    fill: "red",
  },
  {
    name: "Liquidity",
    value: 6.9,
    fill: "green",
  },
  {
    name: "Treasury",
    value: 5.0,
    fill: "blue",
  },
  {
    name: "xLPFi",
    value: 6.1,
    fill: "purple",
  },
];
