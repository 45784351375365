import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "components/Home";
import Tokenomics from "components/Tokenomics";
import Layout from "components/globalComponents/Layout";

const App = () => {
  return (
    <>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />

        <Route
          exact
          path="/tokenomics"
          element={
            <Layout>
              <Tokenomics />
            </Layout>
          }
        />
      </Routes>
    </>
  );
};

export default App;
