import React from "react";
import Banner from "./Banner";
import Overview from "./Overview";
// import Team from "./Team";
import Contact from "./Contact";

const Home = () => {
  return (
    <>
      <Banner />
      <Overview />
      {/* <Team /> */}
      <Contact />
    </>
  );
};

export default Home;
