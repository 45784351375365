import React, { useEffect } from "react";
import { useSnackbar } from "contexts/SnackbarContext";

const Snackbar = () => {
  const { CloseSnackbar, isOpenSnackbar, SnackbarMess, SnackbarType } =
    useSnackbar();

  const handleClose = () => {
    const toast = document.querySelector(".Snackbar");
    toast.classList.remove("active");
    CloseSnackbar();
  };

  useEffect(() => {
    if (isOpenSnackbar === true) {
      const toast = document.querySelector(".Snackbar");
      toast.classList.add("show");
    }
  }, [isOpenSnackbar]);

  useEffect(() => {
    if (isOpenSnackbar === true && SnackbarType !== "Processing") {
      const toast = document.querySelector(".Snackbar");
      toast.classList.add("stop");

      const timer = setTimeout(() => {
        toast.classList.remove("show");
        toast.classList.remove("stop");
        CloseSnackbar();
      }, 7000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SnackbarType !== "Processing"]);

  return (
    <>
      {isOpenSnackbar && (
        <>
          <div className="Snackbar">
            <div className="Snackbar_content">
              <div className="status_section">
                <div className="status">
                  {SnackbarType === "Processing" && (
                    <img
                      src="/images/Loader/Loader.png"
                      alt="Processing"
                      loading="lazy"
                    />
                  )}
                  {SnackbarType === "Success" && (
                    <i className="zmdi zmdi-check-circle Success" />
                  )}
                  {SnackbarType === "Info" && (
                    <i className="zmdi zmdi-info-outline Info" />
                  )}
                  {SnackbarType === "Error" && (
                    <i className="zmdi zmdi-close-circle-o Error" />
                  )}
                  <span className={SnackbarType}>{SnackbarType}</span>
                </div>
                {SnackbarType !== "Processing" && (
                  <div className="close_Snackbar">
                    <i
                      className="zmdi zmdi-close"
                      onClick={() => handleClose()}
                    />
                  </div>
                )}
              </div>
              <div className="message_section">
                <span>{SnackbarMess}</span>
              </div>

              <div className="progress_bar"></div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Snackbar;
