import React from "react";
import { OverviewRegistry, ImgRegistry } from "registry";
import { Link } from "react-router-dom";
import Slide from "react-slick";

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 4000,
  pauseOnHover: true,
  arrows: false,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        infinite: true,
        dots: true,
        slidesToScroll: 1,
      },
    },
  ],
};

const Overview = () => {
  return (
    <>
      <section className="Overview" id="Overview">
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex justify-content-center text-center">
              <div className="Overview_title">
                <h1>Overview</h1>
              </div>
            </div>
            <div className="col-12 mt-lg-5 mt-md-5 mt-3 pt-lg-4 pt-md-4 pt-2">
              <div>
                <Slide {...settings}>
                  {OverviewRegistry.map((item) => {
                    return (
                      <div className="col-12" key={item.id}>
                        <div className="Overview_card">
                          <div className="img_section">
                            <img
                              src={item.imgUrl}
                              alt={item.title}
                              loading="lazy"
                            />
                            {item.id === 4 && (
                              <img
                                src={ImgRegistry.ETH}
                                alt="ETH"
                                loading="lazy"
                                className="zSOL_img"
                              />
                            )}
                          </div>
                          <div className="title mt-lg-4 mt-md-4 mt-3">
                            <span>{item.title}</span>
                          </div>
                          <div className="subtitle mt-lg-3 mt-md-3 mt-2">
                            <span>{item.subtitle}</span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slide>
              </div>
            </div>
          </div>
          <div className="row mt-lg-5  mt-md-4 mt-4 pt-lg-5">
            <div className="col-12 d-flex justify-content-center">
              <Link to="/tokenomics">
                <button>Tokenomics</button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Overview;
