import React from "react";
import { Link, useLocation } from "react-router-dom";
import { NavbarRegistry } from "registry";

const Header = () => {
  const location = useLocation();

  const openNav = () => {
    document.getElementById("mySidenav").style.width = "250px";
  };

  const closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
  };

  return (
    <>
      <div id="mySidenav" className="sideNav mb-4">
        <div className="container-fluid">
          <div className="row d-flex align-items-center mt-2">
            <div className="col-8 d-flex justify-content-center">
              <img
                src="/images/logo.png"
                alt="Loading..."
                className="small_logo"
              />
            </div>
            <div className="col-4 d-flex justify-content-end">
              <p className="closeBtn mr-2" onClick={closeNav}>
                <i className="zmdi zmdi-close close_icon" />
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-12 d-flex justify-content-start">
              <ul className="mt-5 ml-3 pl-1">
                {NavbarRegistry.map((nav) => {
                  return (
                    <li key={nav.id}>
                      {nav.id === 1 ? (
                        <Link to={nav.href} onClick={closeNav}>
                          {nav.name}
                        </Link>
                      ) : (
                        <a href={nav.href} onClick={closeNav}>
                          {nav.name}
                        </a>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="navbar_component">
        <div className="container">
          <div className="row">
            <div className="col-12  m-lg-0 p-lg-0">
              <nav className="navbar navbar-light">
                <i className="zmdi zmdi-menu navbar-icon" onClick={openNav} />

                <Link to="/" className="navbar-brand mb-2 ml-lg-0 ml-md-2 ml-3">
                  <img src="/images/logo.png" alt="Loading..." />
                </Link>

                <ul className="navbar-nav left_ui_block ml-auto d-flex justify-content-center  align-items-center flex-row">
                  {NavbarRegistry.map((nav) => {
                    return (
                      <li className="nav-item" key={nav.id}>
                        {location.pathname === "/" ? (
                          <>
                            {nav.id === 1 ? (
                              <Link
                                to={nav.href}
                                className={
                                  location.pathname === nav.href
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                              >
                                {nav.name}
                              </Link>
                            ) : (
                              <a href={nav.href}>{nav.name}</a>
                            )}
                          </>
                        ) : (
                          <>
                            {nav.id === 1 && (
                              <Link
                                to={nav.href}
                                className={
                                  location.pathname === nav.href
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                              >
                                {nav.name}
                              </Link>
                            )}
                          </>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
